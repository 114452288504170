import Grid from "@material-ui/core/Grid"
import ReactAudioPlayer from "react-audio-player"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SocialShare from "../components/socialShare"
import {
  Blog,
  Wrapper,
  Title,
  TitleSocialWrapper,
} from "../components/styles/styles"
import Snackbar from "@material-ui/core/Snackbar"
import SuggestionsBox from "../components/suggestion_v2"
import { formatDate } from "../utils/utils"

const BlogTemplate = props => {
  const [show, setShow] = React.useState(false)
  const { data } = props
  // console.log("blogtem",data)

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setShow(false)
  }

  return (
    <Layout>
      <SEO title="Events" />

      <Grid container spacing={1}>
        <Grid item lg={12} xs={12} sm={12}>
          {data.markdownRemark.frontmatter.featuredImage?.childImageSharp
            ?.fixed && (
            <Img
              style={{ width: "100%", height: "600px" }}
              fixed={
                data.markdownRemark.frontmatter.featuredImage?.childImageSharp
                  ?.fixed
              }
            />
          )}
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
          <TitleSocialWrapper>
            <Title
              size="36px"
              dangerouslySetInnerHTML={{
                __html: data.markdownRemark.frontmatter.title,
              }}
            />

            <SocialShare url={props.location.href} />
          </TitleSocialWrapper>

          <Snackbar
            open={show}
            onClose={handleClose}
            autoHideDuration={6000}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={
              <span id="message-id">
                This arcticle is also available in audio format. Click play
                button to listen to the article
              </span>
            }
          />

          {data.markdownRemark.frontmatter.audio && (
            <ReactAudioPlayer
              src={data.markdownRemark.frontmatter.audio.audio.guid}
              // autoPlay
              controls
              className={show}
              onCanPlayThrough={() => setShow(true)}
              style={{
                height: "40px",
              }}
            />
          )}
        </Grid>

        <Grid item lg={8} xs={12} sm={8}>
          <Wrapper>
            <Title size="16px">
              {formatDate(data.markdownRemark.frontmatter.date)}{" "}
            </Title>
            <hr />
            <Title size="24px">
              {data.markdownRemark.frontmatter.subheading}
            </Title>

            <Blog
              dangerouslySetInnerHTML={{
                __html: data.markdownRemark.html,
              }}
            />
          </Wrapper>
        </Grid>
        <Grid item lg={4} xs={12} sm={4}>
          <SuggestionsBox data={data.markdownRemark.frontmatter} />
        </Grid>
      </Grid>
    </Layout>
  )
}
export default BlogTemplate

export const BlogTemplateQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      html
      frontmatter {
        title
        content
        date
        subtitle
        subheading
        suggested_articles
        suggested_recipes
        suggested_wines
        featuredImage {
          childImageSharp {
            fixed(quality: 100, height: 600) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
