import { SuggestionsContainer, SuggestionTitle, Title } from "./styles/styles"
import { Link } from "gatsby"
import React from "react"

const SuggestionsBox = props => {
  const RenderList = props => {
    if (props.data) {
      return props.data.map((item, index) => {
        let slug = item.replace(/ /g, "-").toLowerCase()
        let prefixedSlug = `${props.type}/${slug}`
        return (
          <Title key={index} size="16px">
            <Link to={prefixedSlug}>● {item}</Link>
          </Title>
        )
      })
    }
    return ""
  }
  return (
    <SuggestionsContainer>
      <SuggestionTitle>CURIOSITIES</SuggestionTitle>
      <RenderList type="article" data={props.data.suggested_articles} />

      <SuggestionTitle> EAT IF YOU LIKE IT</SuggestionTitle>
      <RenderList type="recipe" data={props.data.suggested_recipes} />

      <SuggestionTitle>SUGGESTED WINES</SuggestionTitle>
      <RenderList type="wine" data={props.data.suggested_wines} />
      {/*<List data={props.data.suggestedRecipes}/>*/}

      {/*<SuggestionTitle>CATEGORIES</SuggestionTitle>*/}
      {/*<List data={props.data.suggestedCategories}/>*/}
    </SuggestionsContainer>
  )
}

export default SuggestionsBox
